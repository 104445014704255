import React, {useState} from "react";
import { notifyMe } from "../../helper";
import { subscribeNewsLetter } from '../../lib/api/common.jsx';
import { validateEmail } from "../../lib/utils/validations"
import { useRouter } from 'next/router';
import { getText } from '../../lib/utils/langTexts'

const SubscribeNewsLetter = ({webTexts}) => {
    
    const router = useRouter();
    const [email, setEmail] = useState('');
    const [conditionsAccepted, setConditionsAccepted] = useState(false);

    const handleSubscribe = async () => {
        if (email == '') {
            notifyMe({ key: 'newsLetter', method: 'error', message: 'Error', description: 'Debes poner un email' });
            return;
        }
        if (!validateEmail(email)) {
            notifyMe({ key: 'newsLetter', method: 'error', message: 'Error', description: 'El email no parece correcto' });
            return;
        }
        if(!conditionsAccepted) {
            notifyMe({ key: 'newsLetter', method: 'error', message: 'Error', description: 'Please check the checkbox' });
            return;
        }

        const obj = {email:email, zone:'Newsletter'};

        const response = await subscribeNewsLetter(obj,router.asPath);

        if(response.responseCode == '201'){
            notifyMe({ key: 'newsLetter', method: 'success', message: 'Confirmación', description: 'Tu email ha sido añadido correctamente a nuestra newsletter, revisa tu correo electrónico para activar la suscripción' });
        }else if(response.responseCode == '142'){
            notifyMe({ key: 'newsLetter', method: 'error', message: 'Usuario ya existente', description: 'Tu email ya consta en nuestra newsletter' });
        }else{            
            notifyMe({ key: 'newsLetter', method: 'error', message: 'Fallo', description: 'A ocurrido algún error y no hemos podido añadir tu email a nuestra newsletter' });
        }
    }
    const handleCheckConditions = (target) => {
        setConditionsAccepted(target.checked)
    }
    return (
        <div className="container-fluid footerform">
            <div className="container footer_sec">
                <div className="row">
                <div className="col-12 form2">
                    <h2>{getText(webTexts,"NEWS001")}</h2>
                    <p>{getText(webTexts,"NEWS002")}</p>
                    <label>E-mail</label>
                    <div className="subs-email"><input type="email" title="Valor del input" name="email" onChange={(event) => setEmail(event.target.value)}/></div>
                    <div className="form-group">
                        <input type="checkbox" id="newslettercheckbox" name="newslettercheckbox" onChange={(event) => handleCheckConditions(event.target)}/>
                        <label htmlFor="newslettercheckbox" className="subs-privacy-link" dangerouslySetInnerHTML={{__html:getText(webTexts,"NEWS003")}}></label>
                    </div>
                    <div className="footer-btn-wrapper">
                      <div className="btn-wrapper"><button className={!conditionsAccepted? "btn2 subs_disabled grey-btn" : "btn2"} onClick={() => handleSubscribe()}>{getText(webTexts,"NEWS004")}</button></div>
                      <div className="social1">
                          <ul className="nav-tabs icons">
                              <li className="nav-item box1"><a rel="noreferrer"  target="_blank" href="https://www.facebook.com/ECCEdiciones"><i className="fab fa-facebook-square socialico1" aria-hidden="true"></i></a></li>
                              <li  className="nav-item box1"><a rel="noreferrer"  target="_blank" href="https://twitter.com/eccediciones"><i className="fab fa-x-twitter socialico1" aria-hidden="true"></i></a></li>
                              <li  className="nav-item box1"><a rel="noreferrer"  target="_blank" href="https://instagram.com/eccediciones"><i className="fab fa-instagram socialico1" aria-hidden="true"></i></a></li>
                              <li  className="nav-item box1"><a rel="noreferrer"  target="_blank"href="https://www.youtube.com/user/CanalECCEdiciones"><i className="fab fa-youtube socialico1" aria-hidden="true"></i></a></li>
                          </ul>
                      </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    )
}

export default SubscribeNewsLetter;
