export const textsWeb = {
    foreignRights : {WebPages: ["foreignRights"]},
    library : {WebPages: ["biblioteca"]},
    webs: {WebPages: ["Webs"]},
    shops: {WebPages: ["Tiendas"]},
    history: {WebPages: ["Historia"]},
    distribucion:  {WebPages: ["Distribucion"]},
    legalAdvise: {WebPages: ["Aviso_Legal"]},
    help: {WebPages: ["ayuda"]},
    meetUs:{WebPages: ["conocenos"]},
    contactUs:{WebPages: ["contacto"]},
    productsListing:{WebPages: ["productsListing"]},
    productDetails:{WebPages: ["productDetails"]},
    newsletter:{WebPages: ["newsletter"]},
}
export const getText = (webtexts, code)=>{    
    if (!webtexts || webtexts.length == 0) return;
    return webtexts?.find(x=> x.webCode == code )?.webText;
}